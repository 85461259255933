import axios from "axios";
const service = axios.create({
  baseURL: 'https://admin.pinksale.tw/prod-api/launchpad/',
});
//admin.pinksale.tw

// ajax请求拦截器;
service.interceptors.request.use((config) => {
  return config;
});

// ajax响应拦截器;
service.interceptors.response.use(
  (res) => {
    // 获取后端返回的数据与状态码;
    const { data, code } = res.data;
    // 状态码位200时, 则无异常;
    if (code == 200) {
      return data;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default service;
