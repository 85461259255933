import request from "@/utils/request";

export function getInfo(data) {
  return request({
    url: `${data}`,
    method: 'get',
  })
}

export function getServerAdd(data) {
  return request({
    url: `address/${data}`,
    method: 'get',
  })
}