export default {
	info:{
		home:"主页",
		launchpads:"启动板",
		createLaunchpad:"创建普通预售",
		createFairLaunch:"创建超募预售",
		createDutchAuction:"Create dutch auction",
		createSubscription:"创建订阅",
		createToken:"创建代币",
		launchpadList:"预售列表",
		privateSale:"Private Sale",
		createPrivateSale:"Create Private Sale",
		privateSaleList:"Private Sale List",
		pinkLock:"粉红锁",
		createLock:"创建锁",
		token:"代币",
		liquidity:"流动性",
		airdrop:"Airdrop",
		createAirdrop:"创建空投",
		airdropList:"空投列表",
		leaderboard:"排行榜",
		poolsAlert:"资金池警告",
		KYCAudit:"实名认证&审计",
		docs:"使用指南",
		telegram:"电报",
		english:"English",
		china:"简体中文",
		ja:"日本語",
		TV:"Tiếng Việt",
		turkey:"Turkey",
		twitter:"推特",
		facebook:"Facebook",

		connect:"连接",
		firstReleaseForPresale:"首次预售",
		launch:"预售",
		presale:"预售",
		fairLaunch:"超募预售",
		upcoming:"即将到来",
		saleEnded:"销售结束",
		saleLive:"拍卖现场",
		presaleAddress:"预售地址",
		tokenName:"令牌名称",
		tokenSymbol:"令牌符号",
		tokenDecimals:"标记小数",
		totalSupply:"总供给",
		tokenAddress:"代币地址",
		notSend:"不发送 BNB 到代币地址！",
		tokensForPresale:"预售代币",
		tokensForLiquidity:"流动性代币",
		presaleRate:"预售价格",
		listingRate:"上市率",
		initialMarketCap:"初始市值（估计值）",
		softCap:"软顶",
		hardCap:"硬顶",
		unsoldTokens:"未售出的代币",
		presaleStartTime:"预售开始时间",
		presaleEndTime:"预售结束时间",
		listingOn:"上市",
		listingRate:"上市率",
		liquidityPercent:"流动性百分比",
		liquidityLockupTime:"流动性锁定时间",
		tokenMetrics:"代币指标",
		amount:"数量",
		lockRecords:"锁定记录",
		unlockTime:"解锁时间",	
		viewAll:"查看全部",	
		view:"查看",
		cycle:"周期",
		makeSure:"确保网站是pinksale.finance!",
		presaleEndsIn:"预售结束",
		subscriptionStartsIn:"预售开始于",
		saleEnd:"此池已结束",
		saleType:"销售类型",
		vestingForPresale:"预售权",
		currentRate:"当前利率",
		buy:"购买",
		max:"最大",
		max1:'最大',
		status:"状态",
		statusIncoming:"incoming",
		inprogress:"inprogress",
		ended:"ended",
		minimumBuy:"最低购买额",
		maximumBuy:"最大购买额",
		totalContributors:"贡献者总数",
		youPurchased:"您购买的",
		copyright:"免责声明：所提供的信息不得以任何方式构成您是否应投资所讨论的任何产品的建议。对于因提供或发布任何材料而采取行动或不采取行动的任何人所遭受的任何损失，我们不承担任何责任。",
		needConnect:"您需要先连接metamask wallet!",
		needInstall:"您必须使用 Trustwallet 或安装 Metamask 才能使用此服务,您可以从以下地址安装它:https://metamask.io/download.html",
		minBuy:"最小购买额是",
		maxBuy:"最大购买额是"
	}

}