import Vue from 'vue'
import App from './App.vue'

import '@/common/style/main.css'
import '@/common/style/detail.css'
import i18n from '@/lang/index'
//import * as echarts from 'echarts'
import Web3 from 'web3'
//Vue.prototype.$echarts = echarts;
Vue.prototype.Web3 = Web3;
Vue.config.productionTip = false


new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')
