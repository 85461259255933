export default {
	info:{
		home:"Home",
		launchpads:"Launchpads",
		createLaunchpad:"Create launchpad",
		createFairLaunch:"Create fair launch",
		createDutchAuction:"Create dutch auction",
		createSubscription:"Create subscription",
		createToken:"Create token",
		launchpadList:"Launchpad list",
		privateSale:"Private Sale",
		createPrivateSale:"Create Private Sale",
		privateSaleList:"Private Sale List",
		pinkLock:"PinkLock",
		createLock:"Create Lock",
		token:"Token",
		liquidity:"Liquidity",
		airdrop:"Airdrop",
		createAirdrop:"Create Airdrop",
		airdropList:"Airdrop List",
		leaderboard:"Leaderboard",
		poolsAlert:"Pools Alert",
		KYCAudit:"KYC & Audit",
		docs:"Docs",
		telegram:"Telegram",
		english:"English",
		china:"简体中文",
		ja:"日本語",
		TV:"Tiếng Việt",
		turkey:"Turkey",
		twitter:"Twitter",
		facebook:"Facebook",


		connect:"Connect",
		firstReleaseForPresale:"First Release For Presale",
		presale:"Presale",
		launch:"Launch",
		fairLaunch:"Fair Launch",
		upcoming:"Upcoming",
		saleEnded:"Sale Ended",
		saleLive:"Sale Live",
		presaleAddress:"Presale Address",
		tokenName:"Token Name",
		tokenSymbol:"Token Symbol",
		tokenDecimals:"Token Decimals",
		totalSupply:"Total Supply",
		tokenAddress:"Token Address",
		notSend:"Do not send BNB to the token address!",
		tokensForPresale:"Tokens For Presale",
		tokensForLiquidity:"Tokens For Liquidity",
		presaleRate:"Presale Rate",
		listingRate:"Listing Rate",
		initialMarketCap:"Initial Market Cap (estimate)",
		softCap:"Soft Cap",
		hardCap:"Hard Cap",
		unsoldTokens:"Unsold Tokens",
		presaleStartTime:"Presale Start Time",
		presaleEndTime:"Presale End Time",
		listingOn:"Listing On",
		listingRate:"Listing Rate",
		liquidityPercent:"Liquidity Percent",
		liquidityLockupTime:"Liquidity Lockup Time",
		tokenMetrics:"Token Metrics",
		amount:"Amount",
		lockRecords:"Lock records",
		unlockTime:"Unlock time",	
		viewAll:"view All",	
		view:"View",
		cycle:"Cycle",
		makeSure:"Make sure the website is pinksale.finance!",
		presaleEndsIn:"Presale Ends In",
		subscriptionStartsIn:"Presale Starts In",
		saleEnd:"This pool has ended",
		saleType:"Sale Type",
		vestingForPresale:"Vesting For Presale",
		currentRate:"Current Rate",
		buy:"Buy",
		max:"MAX",
		max1:'max',
		status:"Status",
		statusIncoming:"incoming",
		inprogress:"inprogress",
		ended:"ended",
		currentRate:"Current Rate",
		minimumBuy:"Minimum Buy",
		maximumBuy:"Maximum Buy",
		totalContributors:"Total Contributors",
		youPurchased:"You purchased",
		copyright:"Disclaimer: The information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. We accept no liability for any loss occasioned to any person acting or refraining from action as a result of any material provided or published.",
		needConnect:"You must connect your metamask wallet !",
		needInstall:"You have to use Trustwallet or install Metamask to use this service, you can install it from :  https://metamask.io/download.html",
		minBuy:"The Minimum Buy is ",
		maxBuy:"The Maximum Buy is "
	}

}